<template>
  <div class="cities">
    <Header/>
    <div class="main">
      <div class="bg">
        <div class="title">
          <p>54 CITIES IN UTOPIA</p>
          <p class="yellow">LAND IN THE</p>
          <p class="yellow">SANDBOX</p>
        </div>
        <div class="content">
          <p>Exclusive NFTs and New Gaming Experience</p>
          <p>BUILDING BUSINESS IN THE DIGITAL WORLD</p>
          <p>CREATING VALUE IN THE METAVERSE</p>
        </div>
        <div class="img">
          <img src="@/assets/imgs/city_img.png" alt="">
        </div>
      </div>
      <div class="sandbox">
        <div class="pages">
          <van-pagination
            v-model="page"
            :total-items="total"
            :items-per-page="limit"
            @change="getCities"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
        <div v-for="item in cityList" class="item">
          <div class="title">
            <p class="name">{{ item.name }}</p>
            <p class="size">{{ item.size }} IN THE SANDBOX</p>
          </div>
          <div class="wrap">
            <div class="img"><img :src="item.images_area_url" alt=""></div>
            <div class="content">
              <div class="name">{{ item.name }}</div>
              <div class="imgWrap">
                <div class="logo">
                  <img src="@/assets/imgs/logo_city.png" alt="">
                  <img :src="item.images_icon_url" alt="">
                </div>
                <div class="info">
                  <div class="line">
                    <img src="@/assets/imgs/icon_size.png" alt="">
                    <p>Land Size：{{ item.size }}</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/imgs/icon_pos.png" alt="">
                    <p>Location：{{ item.coordinate }}</p>
                  </div>
                </div>
                <div class="pic"><img :src="item.images_url" alt=""></div>
              </div>
            </div>
            <a :href="item.url" class="btn">Click to View cities in the sandbox</a>
          </div>
        </div>
        <div class="pages">
          <van-pagination
              v-model="page"
              :total-items="total"
              :items-per-page="limit"
              @change="getCities"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
      </div>
    </div>
    <Follow/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Follow from "@/components/Follow";
import Footer from "@/components/Footer";
import { getCityList } from '@/api'

export default {
  name: "Cities",
  components: { Header, Follow, Footer },
  data() {
    return {
      cityList: [],
      page: 1,
      limit: 6,
      total: 0
    }
  },
  created() {
    this.getCities()
  },
  methods: {
    getCities() {
      getCityList({
        page: this.page,
        limit: this.limit
      }).then(res => {
        this.cityList = res.data.list
        this.total = res.data.count
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.cities{
  .main{
    margin-top: 49px;
    position: relative;
    color: #fff;
    background-color: #12191c;
    .bg{
      padding: 53px 0 46px 0;
      background: url("~@/assets/imgs/cities_bg.png") no-repeat;
      background-size: 100% 100%;
      text-align: left;
      .title{
        font-family: Montserrat-ExtraBold, Verdana;
        font-size: 28px;
        font-weight: bold;
        line-height: 40px;
        margin-left: 30px;
        .yellow{
          color: #FFDD00;
        }
      }
      .content{
        font-family: Montserrat-Regular, Verdana;
        font-size: 12px;
        line-height: 20px;
        color: #C3C3C3;
        margin: 0 0 24px 30px;
      }
      .img{
        width: 307px;
        margin: 0 auto;
      }
    }
    .sandbox{
      padding: 20px 17px 30px 17px;
      .item{
        margin-bottom: 26px;
        .title{
          font-family: Montserrat-Bold, Verdana;
          font-size: 16px;
          font-weight: bold;
          line-height: 23px;
          margin-bottom: 15px;
          .name{
            color: #05A990;
          }
          .size{
            color: #036459;
          }
        }
        .wrap{
          background: linear-gradient(180deg, #0B1A20 0%, #131D23 100%);
          box-shadow: 0 3px 15px 8px rgba(0, 0, 0, 0.3),inset 0 0 20px 3px rgba(0, 255, 183, 0.36);
          border: 3px solid #0ba999;
          border-radius: 18px;
          padding-bottom: 30px;
          overflow: hidden;
          .content{
            padding: 15px 0;
            .name{
              font-family: Montserrat-Bold, Verdana;
              font-size: 16px;
              font-weight: bold;
              line-height: 23px;
              text-transform: uppercase;
              text-align: left;
              padding-left: 26px;
              margin-bottom: 10px;
            }
            .imgWrap{
              position: relative;
              .logo{
                padding-left: 17px;
                display: flex;
                align-items: center;
                margin-bottom: 36px;
                img{
                  width: 53px;
                }
              }
              .info{
                padding-left: 26px;
                .line{
                  display: flex;
                  align-items: center;
                  margin-bottom: 2px;
                  img{
                    width: 14px;
                    margin-right: 9px;
                  }
                  p{
                    font-family: Montserrat-Regular, Verdana;
                    font-size: 12px;
                  }
                }
                .line:last-child{
                  margin-bottom: 0;
                }
              }
              .pic{
                width: 37%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 8%;
              }
            }
          }
          .btn{
            display: block;
            font-size: 12px;
            width: 83%;
            line-height: 29px;
          }
        }
      }
      .item:last-child{
        margin-bottom: 0;
      }
      .pages{
        margin: 0 auto 20px auto;
        /deep/ .van-pagination__item{
          background-color: transparent;
          color: #9E9E9E;
          font-family: Montserrat-Bold, Verdana;
          font-size: 14px;
          font-weight: bold;
        }
        /deep/ .van-pagination__item--active{
          color: #05A990;
        }
        /deep/ .van-pagination__item::after{
          border: none;
        }
        /deep/ .van-pagination__next, /deep/ .van-pagination__prev{
          color: #D8D8D8;
        }
      }
      .pages:last-child{
        margin-bottom: 0;
      }
    }
  }
}
</style>
